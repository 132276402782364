import type * as React from 'react';
export const ArigatouIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={110}
    height={154}
    fill="none"
    viewBox="0 0 110 154"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#1D1D1D"
      d="M55.3854 152.568c27.4567 0 49.7146-2.855 49.7146-6.377 0-3.521-22.2579-6.376-49.7146-6.376-27.4568 0-49.7149 2.855-49.7149 6.376 0 3.522 22.2581 6.377 49.7149 6.377Z"
    />
    <path
      fill="#FEE3B5"
      d="M102.309 90.1002c0-40.549-21.0213-63.5633-46.9248-63.5633-25.9036 0-46.9253 22.9146-46.9253 63.5633 0 26.3018 8.7674 41.3458 22.018 48.7188.6974 4.483 3.0885 7.273 6.2767 7.671 2.391.399 4.7821-.697 6.6751-2.989 3.7859.797 7.7711 1.096 11.9555 1.096 4.0848 0 8.0699-.398 11.8558-1.096 1.893 2.292 4.2841 3.388 6.6752 2.989 3.1881-.498 5.5792-3.188 6.2766-7.671 13.2507-7.373 22.1172-22.417 22.1172-48.7188Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M102.309 90.1002c0-40.549-21.0213-63.5633-46.9248-63.5633-25.9036 0-46.9253 22.9146-46.9253 63.5633 0 26.3018 8.7674 41.3458 22.018 48.7188.6974 4.483 3.0885 7.273 6.2767 7.671 2.391.399 4.7821-.697 6.6751-2.989 3.7859.797 7.7711 1.096 11.9555 1.096 4.0848 0 8.0699-.398 11.8558-1.096 1.893 2.292 4.2841 3.388 6.6752 2.989 3.1881-.498 5.5792-3.188 6.2766-7.671 13.2506-7.373 22.1172-22.417 22.1172-48.7188Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M55.3849 2.6258c38.7557 0 52.1061 20.0255 52.1061 44.8331s-13.3504 44.833-52.1061 44.833c-38.7556 0-52.106-20.0254-52.106-44.833 0-24.8076 13.3504-44.833 52.106-44.833Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M47.9116 62.7022c-.4981-1.9926-2.2914-3.3874-4.3837-3.2878-2.4907.0997-4.4833 2.0922-4.3836 4.583v1.2952"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.359}
      d="M39.3414 73.8562c3.3554-.0777 6.0311-2.0581 5.9764-4.4234-.0548-2.3653-2.8193-4.2198-6.1748-4.1421-3.3554.0777-6.0311 2.0581-5.9763 4.4235.0547 2.3653 2.8193 4.2197 6.1747 4.142Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M63.6529 62.3036c.3985-1.9926 2.1918-3.487 4.284-3.487 2.4908-.0997 4.4833 1.8929 4.583 4.3836v1.2952"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.359}
      d="M72.713 72.7619c3.2455-.0752 5.8341-1.9644 5.7818-4.2196-.0522-2.2553-2.7255-4.0227-5.9709-3.9475-3.2454.0751-5.834 1.9643-5.7818 4.2196.0523 2.2553 2.7255 4.0226 5.9709 3.9475Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M59.8661 67.2852c0 2.0922-1.6937 3.6862-3.7859 3.6862s-3.6863-1.6936-3.6863-3.7859"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M57.478 29.6251c-.1993-4.284-2.3911-8.3688-6.177-10.6603-3.8856-2.391-8.4685-2.4907-12.354-.6974.1992 4.2841 2.3911 8.3689 6.177 10.6603 3.8855 2.2915 8.4684 2.4908 12.354.6974Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M57.4773 29.6254c5.6789-2.4907 9.3652-6.8744 9.5644-11.9555.0996-5.081-3.3874-9.5643-8.867-12.4536-5.5792 2.5904-9.3651 6.8744-9.5643 11.9555-.0997 5.0811 3.3873 9.5644 8.8669 12.4536Z"
    />
    <path
      fill="#99E2CD"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M57.4776 29.6251c3.8855 1.893 8.6677 1.9926 12.8521-.2989 4.1845-2.2914 6.8744-6.2766 7.3726-10.5606-3.8856-1.893-8.6677-1.9926-12.8522.2989-4.1844 2.1918-6.7747 6.2766-7.3725 10.5606Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M90.3539 94.1846c-7.0736 2.9888-15.5421 3.9851-20.0254 3.9851-9.5644 0-15.044 3.6863-15.044 8.2693 0 4.583 5.4796 8.269 15.044 8.269"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.004}
      d="M19.4182 93.7863c7.1732 3.2877 16.2395 4.3836 20.922 4.3836 9.5644 0 15.044 3.6861 15.044 8.2691s-5.4796 8.269-15.044 8.269"
    />
  </svg>
);
