import type * as React from 'react';
export const ConversationIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={223}
    height={145}
    fill="none"
    viewBox="0 0 223 145"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M138.94 7.5c-4.41 0-8.64 1.752-11.758 4.8705l-.511.5108c-1.561 1.5609-1.561 4.0917 0 5.6526 1.561 1.561 4.091 1.561 5.652 0l.511-.5108a8.637 8.637 0 0 1 6.106-2.5291h1.822c1.61 0 3.04 1.0304 3.549 2.558.654 1.96-.406 4.0786-2.366 4.7319l-5.291 1.7637c-1.632.544-2.733 2.0714-2.733 3.7919v4.2106c0 2.2075 1.79 3.997 3.997 3.997 2.208 0 3.997-1.7895 3.997-3.997v-1.3298l2.558-.8526c6.149-2.0495 9.472-8.6952 7.422-14.8437-1.597-4.7918-6.082-8.024-11.133-8.024h-1.822Z"
    />
    <path
      fill="#FFCE74"
      stroke="#1D1D1D"
      strokeWidth={3}
      d="M142.129 45.1818c0 2.3255-1.885 4.2107-4.21 4.2107-2.326 0-4.211-1.8852-4.211-4.2107 0-2.3254 1.885-4.2106 4.211-4.2106 2.325 0 4.21 1.8852 4.21 4.2106Z"
    />
    <path
      fill="#1D1D1D"
      d="M36.6326 138.343c16.9178 0 30.6325-2.48 30.6325-5.539 0-3.058-13.7147-5.538-30.6325-5.538-16.9179 0-30.6325 2.48-30.6325 5.538 0 3.059 13.7146 5.539 30.6325 5.539Z"
    />
    <path
      fill="#fff"
      d="M49.6122 110.653c.2596-.52.3462-1.039.4327-1.645.4327-2.942-1.1249-5.624-3.6344-5.97-2.4229-.346-4.7593 1.73-5.1919 4.672-.0865.606-.0865 1.125 0 1.731"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.025}
      d="M49.6122 110.653c.2596-.52.3462-1.039.4327-1.645.4327-2.942-1.1249-5.624-3.6344-5.97-2.4229-.346-4.7593 1.73-5.1919 4.672-.0865.606-.0865 1.125 0 1.731"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.609}
      d="M59.2177 134.017c-2.0768 1.99-4.6727 1.99-5.7977-.087-1.1249-2.077-.2596-5.278 1.8172-7.269 2.0768-1.99 4.6728-1.99 5.7977.087 1.1249 1.99.3461 5.278-1.8172 7.269Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.609}
      d="M58.9584 124.757c-1.6442 8.048-8.9129 13.326-21.2005 10.73-12.2876-2.596-15.6624-9.345-12.6338-16.96 2.2499-5.625 6.9226-12.721 18.7776-12.115 12.4607.606 16.7008 10.298 15.0567 18.345Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.613}
      d="M51.0552 121.961c-1.0976 1.354-3.3648 2.057-5.1024 1.429"
    />
    <path
      fill="#1D1D1D"
      d="M52.5584 115.172c.925-.241 1.4795-1.186 1.2386-2.111-.2408-.925-1.1859-1.48-2.1108-1.239-.925.241-1.4795 1.186-1.2387 2.111.2409.925 1.186 1.48 2.1109 1.239ZM37.9939 120.104c.9156-.274 1.4354-1.239 1.1611-2.154-.2743-.916-1.2389-1.436-2.1545-1.161-.9156.274-1.4354 1.238-1.1611 2.154.2743.916 1.2389 1.435 2.1545 1.161Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.609}
      d="M41.2195 132.892c.4327 2.769-1.0384 4.846-3.2882 4.673-2.2498-.173-4.4997-2.596-4.9323-5.365-.4327-2.769 1.0383-4.846 3.2882-4.673 2.2498.173 4.4997 2.596 4.9323 5.365Z"
    />
    <path
      fill="#fff"
      d="M32.6529 113.248c-.3461-.433-.6923-.865-1.2115-1.212-2.3363-1.817-5.4515-1.817-7.0091.174-1.5576 1.903-.8653 5.018 1.4711 6.836.4326.346.9518.692 1.471.865"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.025}
      d="M32.6529 113.248c-.3461-.433-.6923-.865-1.2115-1.212-2.3363-1.817-5.4515-1.817-7.0091.174-1.5576 1.903-.8653 5.018 1.4711 6.836.4326.346.9518.692 1.471.865"
    />
    <ellipse cx={141.82} cy={125.413} fill="#1D1D1D" rx={69.82} ry={7.74} />
    <ellipse
      cx={88.595}
      cy={118.585}
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      rx={8.375}
      ry={5.728}
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M196.316 100.719c-4.903-3.6546-7.969 3.818-11.288 6.863-8.81.82-22.062 4.497-4.596 12.648 15.836 7.391 29.09 1.09 35.137-4.078 1.012-.865 1.169-2.333.495-3.482-.848-1.444-2.691-1.815-4.283-1.296-5.222 1.704-4.244-10.655-15.465-10.655Z"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M190.376 119.43c-13.535 9.887-81.304 7.58-92.2767 3.448-8.7688-2.015-21.362-14.777-6.4455-38.0845 13.8642-21.6629 38.5812-24.8041 64.3482-19.609 33.37 6.7279 51.293 41.8875 34.374 54.2455Z"
    />
    <circle
      cx={2.749}
      cy={2.749}
      r={2.749}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 140.571 112.727)"
    />
    <circle
      cx={2.749}
      cy={2.749}
      r={2.749}
      fill="#1D1D1D"
      transform="matrix(1 0 0 -1 96.97 110.583)"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M121.025 81.8446c-10 7.1054-14.341 18.1634-9.697 24.6994 2.755 3.877 8.176 5.607 16.001 1.496"
    />
    <path
      fill="#1D1D1D"
      d="M118.781 115.295c2.447.111 4.647-.042 4.575 1.558-.073 1.6-2.168 3.699-4.551 3.591-2.382-.108-4.277-2.43-4.206-3.989.07-1.558 1.735-1.271 4.182-1.16Z"
    />
  </svg>
);
