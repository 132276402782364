import type * as React from 'react';
export const FishyIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={141}
    height={147}
    fill="none"
    viewBox="0 0 141 147"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <ellipse cx={60.91} cy={141.915} fill="#1D1D1D" rx={43.749} ry={4.079} />
    <path
      fill="#BFEDE0"
      d="M114.554 82.4157c-5.835-3.4327-13.352-2.7792-17.2142-1.7782L88.9296 93.391c-.0578 7.646 5.8344 12.803 8.729 14.712 15.1614-9.2131 16.6004-20.5167 16.8954-25.6873Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M97.3394 80.638c3.8616-1.0011 11.6006-1.6315 17.4366 1.8012-.295 5.1705-1.957 16.4511-17.1177 25.6638-2.8947-1.908-8.7869-7.066-8.7291-14.7116"
    />
    <path
      fill="#BFEDE0"
      d="M96.0289 85.2275C84.6961 107.363 57.5645 116.121 35.4286 104.788c-22.1358-11.3325-30.8935-38.4641-19.5607-60.6 11.3327-22.1358 38.4644-30.8935 60.6002-19.5607C98.604 35.96 107.362 63.0917 96.0289 85.2275Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="m8.69 40.5026 4.0122 9.493-9.217 2.9201M45.7817 47.4425c1.5325-2.9935.3482-6.6626-2.6453-8.1951-2.9935-1.5326-6.6626-.3483-8.1951 2.6452"
    />
    <circle
      cx={55.478}
      cy={92.116}
      r={3.992}
      fill="#F8F9FB"
      stroke="#1D1D1D"
      strokeWidth={3}
      transform="rotate(27.11 55.478 92.116)"
    />
    <circle
      cx={77.763}
      cy={83.504}
      r={5.094}
      fill="#F8F9FB"
      stroke="#1D1D1D"
      strokeWidth={3}
      transform="rotate(27.11 77.763 83.504)"
    />
    <circle
      cx={33.751}
      cy={83.731}
      r={5.094}
      fill="#F8F9FB"
      stroke="#1D1D1D"
      strokeWidth={3}
      transform="rotate(27.11 33.75 83.73)"
    />
    <circle
      cx={88.18}
      cy={66.38}
      r={3.625}
      fill="#F8F9FB"
      stroke="#1D1D1D"
      strokeWidth={3}
      transform="rotate(27.11 88.18 66.38)"
    />
    <circle
      cx={56.922}
      cy={72.581}
      r={3.625}
      fill="#F8F9FB"
      stroke="#1D1D1D"
      strokeWidth={3}
      transform="rotate(27.11 56.922 72.58)"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M96.0289 85.2275C84.6961 107.363 57.5645 116.121 35.4286 104.788c-22.1358-11.3325-30.8935-38.4641-19.5607-60.6 11.3327-22.1358 38.4644-30.8935 60.6002-19.5607C98.604 35.96 107.362 63.0917 96.0289 85.2275Z"
    />
    <path
      fill="#BFEDE0"
      d="M99.8035 27.0045c-13.5197-13.777-24.0743-2.8475-27.125 1.9981l16.1567 13.8556c-1.2571-7.1786 5.4907-13.1013 10.9683-15.8537Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M72.1209 28.9454c3.0508-4.8456 15.8293-14.7039 27.6835-1.9397-5.4775 2.7525-12.0067 8.248-10.7496 15.4266"
    />
    <path
      fill="#BFEDE0"
      d="M79.2955 114.306c-12.8144 5.39-16.7849-4.807-17.4633-8.875l12.385-6.7064c-2.8324 4.4194 2.2054 12.2284 5.0783 15.5814Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M61.4452 105.314c.6784 4.068 6.4243 14.172 17.8505 8.991-2.8729-3.353-7.2095-10.298-4.3771-14.7173"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="m131.354 23.2305-11.878 3.8177 11.45 5.057c2.884 1.2743 6.174-.5557 6.605-3.6744.48-3.4753-2.843-6.2723-6.177-5.2003ZM118.756 9.4128l-7.39 4.3341 2.652-8.1824c.668-2.0618 3.11-2.908 4.903-1.6993 1.999 1.347 1.91 4.3307-.165 5.5476Z"
    />
  </svg>
);
