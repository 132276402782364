import type * as React from 'react';
export const AloneIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={113}
    height={135}
    fill="none"
    viewBox="0 0 113 135"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M40.1273 21.7579c.7947-1.9868 2.4834-4.0728 1.6887-6.3576-1.6887-5.0662-10.3311-11.6225-14.5033-9.9338-4.1722 1.6887-5.9603 11.2252-4.6689 16.4901.5961 2.5828 4.1722 3.8741 6.457 4.8675"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M40.1273 21.7579c.7947-1.9868 2.4834-4.0728 1.6887-6.3576-1.6887-5.0662-10.3311-11.6225-14.5033-9.9338-4.1722 1.6887-5.9603 11.2252-4.6689 16.4901.5961 2.5828 4.1722 3.8741 6.457 4.8675"
    />
    <path
      fill="#fff"
      d="M80.4585 19.4743c-.7947-1.9867-2.4835-4.0728-1.6888-6.3576 1.6888-5.0662 10.3312-11.6225 14.5034-9.9338 4.1721 1.6888 5.9602 11.2252 4.6688 16.4901-.596 2.5828-4.1722 3.8742-6.4569 4.8676"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M80.4585 19.4743c-.7947-1.9867-2.4835-4.0728-1.6888-6.3576 1.6888-5.0662 10.3312-11.6225 14.5034-9.9338 4.1721 1.6888 5.9602 11.2252 4.6688 16.4901-.596 2.5828-4.1722 3.8742-6.4569 4.8676"
    />
    <path
      fill="#1D1D1D"
      d="M54.2334 133.214c27.3765 0 49.5696-2.846 49.5696-6.357s-22.1931-6.358-49.5696-6.358-49.5696 2.847-49.5696 6.358c0 3.511 22.1931 6.357 49.5696 6.357Z"
    />
    <path
      fill="#fff"
      d="M110.16 69.7393c-3.178-35.1656-21.7546-57.0199-47.5824-59.106C23.2398 7.4545 13.7034 44.6068 5.7564 67.0572c-9.437 26.2251 5.2649 44.1058 23.7417 52.5498.6954 4.371 3.0795 7.053 6.2583 7.549 2.4834.398 4.9669-.695 6.8543-3.178 3.9735.894 7.947 1.291 11.7219 1.291 4.1721 0 8.245-.199 12.1192-.795 1.788 2.086 4.0728 2.981 6.3576 2.682 2.5828-.397 4.5695-2.284 5.6622-5.264 20.9603-6.755 34.2714-23.4441 31.6884-52.1527Z"
    />
    <path
      fill="#1D1D1D"
      fillRule="evenodd"
      d="M37.5334 13.6211c6.8233-3.5281 15.0795-5.2955 25.1648-4.4805 26.8102 2.1654 45.7358 24.862 48.9538 60.4638v.0007c1.314 14.6117-1.406 26.3158-7.254 35.2619-5.7257 8.76-14.3581 14.735-24.7948 18.229-1.2851 2.978-3.5309 5.074-6.5661 5.541l-.017.002-.017.002c-2.5883.338-5.1135-.576-7.1129-2.571-3.7356.522-7.6204.697-11.5576.697-3.593 0-7.3626-.351-11.1484-1.134-2.0882 2.328-4.8183 3.457-7.6617 3.003-3.7884-.594-6.4293-3.631-7.3556-7.996-9.1654-4.329-17.4466-10.991-22.3218-19.922C.819 91.5097-.5044 80.0355 4.346 66.5537c.5729-1.6185 1.1569-3.3237 1.7628-5.0927 3.549-10.3625 7.8489-22.9169 15.0651-33.093 4.2484-5.9911 9.5464-11.2241 16.3595-14.7469ZM8.9714 62.3526c-.6146 1.7921-1.212 3.5337-1.8033 5.2043l-.0026.0073C2.5804 80.306 3.8895 90.8836 8.474 99.2825c4.6103 8.4465 12.6157 14.8355 21.6466 18.9625l.7303.333.1262.793c.6283 3.95 2.6693 5.94 5.0105 6.306l.0054.001c1.8323.293 3.8021-.471 5.4254-2.606l.5822-.767.9388.212c3.859.868 7.7218 1.254 11.3932 1.254 4.129 0 8.1195-.197 11.8914-.777l.823-.126.5418.632c1.4936 1.742 3.2889 2.392 5.0099 2.174 1.8939-.298 3.5094-1.687 4.4669-4.298l.2515-.686.6952-.224c10.222-3.294 18.4771-8.974 23.8787-17.237 5.402-8.264 8.046-19.2584 6.778-33.3549-3.139-34.7293-21.3665-55.7414-46.212-57.7481-9.5836-.7744-17.2711.9101-23.548 4.1556-6.287 3.2508-11.2457 8.113-15.2919 13.8189-6.9423 9.7898-11.0847 21.8685-14.6457 32.2521Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      d="M89.4981 34.1746c1.2618 0 2.2848-1.0229 2.2848-2.2847 0-1.2619-1.023-2.2848-2.2848-2.2848-1.2619 0-2.2848 1.0229-2.2848 2.2848 0 1.2618 1.0229 2.2847 2.2848 2.2847ZM45.7895 34.1746c1.2618 0 2.2848-1.0229 2.2848-2.2847 0-1.2619-1.023-2.2848-2.2848-2.2848-1.2618 0-2.2848 1.0229-2.2848 2.2848 0 1.2618 1.023 2.2847 2.2848 2.2847Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M28.2722 81.52c7.8406 5.8944 14.8781 3.5683 16.0519-.0573 1.2003-3.7214-3.2231-8.2688-9.3445-10.0179M97.9885 81.6556c-7.8817 5.8355-14.9005 3.4584-16.0474-.1748-1.1727-3.7292 3.2828-8.2431 9.4155-9.947"
    />
    <path
      fill="#1D1D1D"
      d="M72.9087 45.7984c2.7815 0 5.0662 0 5.0662-2.2848 0-2.1854-2.2847-2.2848-5.0662-2.2848H64.465c-2.7815 0-5.0662 0-5.0662 2.2848s2.2847 2.2848 5.0662 2.2848h8.4437Z"
    />
    <path
      fill="#F9AA9E"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.352}
      d="M43.1588 42.1898c.5529-2.1803-1.5423-4.5928-4.6798-5.3885-3.1376-.7956-6.1293.3269-6.6821 2.5072-.5529 2.1804 1.5423 4.5928 4.6798 5.3885 3.1376.7956 6.1293-.3269 6.6821-2.5072ZM99.1067 45.1028c2.9123-1.4122 4.4783-4.1979 3.4963-6.2219-.981-2.0239-4.1377-2.5198-7.0503-1.1076-2.9125 1.4123-4.478 4.1979-3.4966 6.2219.9814 2.024 4.1381 2.5199 7.0506 1.1076Z"
    />
  </svg>
);
