import type * as React from 'react';
export const BadTimesIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={157}
    height={127}
    fill="none"
    viewBox="0 0 157 127"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <ellipse cx={76.236} cy={116.305} fill="#1D1D1D" rx={72.236} ry={8.007} />
    <path
      fill="#EFECFC"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M29.8342 108.869c0 3.478-3.8793 6.298-8.6648 6.298-4.7854 0-8.6648-2.82-8.6648-6.298 0-3.479 7.4013-6.299 12.1868-6.299 4.7854 0 5.1428 2.82 5.1428 6.299ZM137.584 93.1311c-5.073-3.7805-13.21 1.5752-16.644 4.7257-9.114.8482-22.8256 4.6532-4.755 13.0862 16.384 7.647 30.097 1.128 36.353-4.219 1.048-.896 1.209-2.414.512-3.602-.877-1.495-2.784-1.879-4.431-1.342-5.402 1.763-5.524-4.5414-11.035-8.6489Z"
    />
    <path
      fill="#EFECFC"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M126.473 110.115c-14.003 10.229-84.1183 7.843-95.4703 3.568-9.0723-2.085-22.1014-15.2894-6.6686-39.4032C39.767 50.166 71.9646 45.9651 98.4344 54.3038c26.4696 8.3388 45.5436 43.0252 28.0386 55.8112Z"
    />
    <ellipse
      cx={2.844}
      cy={1.844}
      fill="#1D1D1D"
      rx={2.844}
      ry={1.844}
      transform="matrix(1 0 0 -1 77.651 99.67)"
    />
    <ellipse
      cx={2.844}
      cy={1.844}
      fill="#1D1D1D"
      rx={2.844}
      ry={1.844}
      transform="matrix(1 0 0 -1 28.14 99.67)"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M52.6453 73.1224c-8.5529 9.3768-10.5179 21.5101-4.389 27.1006 3.6352 3.316 9.4964 3.876 16.5063-1.996M41.5094 105.745c.9662-1.771 3.7682-5.217 7.2462-4.83"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M68.3632 105.745c-.9663-1.771-3.7682-5.217-7.2462-4.83"
    />
    <path
      fill="#1D1D1D"
      d="M59.9112 23.6352c-2.2602 0-2.0342 8.449-2.0342 8.449 0 1.8677 0 3.3796 2.0342 3.3796s2.0342-1.5119 2.0342-3.3796c-.113 0 .1131-8.449-2.0342-8.449ZM74.9411 3.0019c-1.8975.1449-2.4817 8.668-2.4817 8.668 0 2.3124.1085 5.8287 2.5947 5.8287 3.7794 0 2.5052-8.6675 1.4305-12.2909-.3861-1.3017-.8901-2.2557-1.5435-2.2058ZM88.4521 25.3966c-1.3604.1039-1.7792 6.2145-1.7792 6.2145 0 1.6578.0777 4.1788 1.8603 4.1788 2.7096 0 1.796-6.2141 1.0255-8.8118-.2768-.9333-.6381-1.6173-1.1066-1.5815Z"
    />
    <path
      fill="#EFECFC"
      d="M96.3841 120.986c5.7069-.493 10.0619-4.114 9.7279-8.087-.147-1.746-1.175-3.27-2.765-4.387l-16.4489 1.965c-1.0345 1.251-1.5788 2.704-1.4524 4.206.3343 3.974 5.2316 6.795 10.9384 6.303Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={2.995}
      d="M106.112 112.899c.334 3.973-4.021 7.594-9.728 8.087-5.7068.492-10.6041-2.329-10.9384-6.303-.1264-1.502.4179-2.955 1.4524-4.206"
    />
  </svg>
);
