import type * as React from 'react';
export const SpeedIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={185}
    height={150}
    fill="none"
    viewBox="0 0 185 150"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M36.2722 82.3502c-10.7-.5-20.5 6.6-18.9 12.6 1.4 5.5998 10 8.4998 22.3.6"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.015}
      d="M36.2722 82.3502c-10.7-.5-20.5 6.6-18.9 12.6 1.4 5.5998 10 8.4998 22.3.6"
    />
    <path
      fill="#fff"
      d="M94.3682 25.9545c-.5-2.1-1.8-4.4-.8-6.6 2.4-4.8 11.9998-10.1 15.8998-7.8 3.9 2.3 4.3 12.1 2.3 17.1-1 2.5-4.8 3.2-7.2 3.9"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M94.3682 25.9545c-.5-2.1-1.8-4.4-.8-6.6 2.4-4.8 11.9998-10.1 15.8998-7.8 3.9 2.3 4.3 12.1 2.3 17.1-1 2.5-4.8 3.2-7.2 3.9"
    />
    <path
      fill="#fff"
      d="M62.3682 25.9545c-.5-2.1-1.8001-4.4-.8001-6.6 2.4-4.8 12.0001-10.1 15.9001-7.8 3.9 2.3 4.3 12.1 2.3 17.1-1 2.5-4.8001 3.2-7.2001 3.9"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M62.3682 25.9545c-.5-2.1-1.8001-4.4-.8001-6.6 2.4-4.8 12.0001-10.1 15.9001-7.8 3.9 2.3 4.3 12.1 2.3 17.1-1 2.5-4.8001 3.2-7.2001 3.9"
    />
    <path
      fill="#1D1D1D"
      d="M85.3722 148.75c27.5588 0 49.8998-2.865 49.8998-6.4 0-3.534-22.341-6.4-49.8998-6.4-27.559 0-49.9 2.866-49.9 6.4 0 3.535 22.341 6.4 49.9 6.4Z"
    />
    <path
      fill="#fff"
      d="M133.272 110.65c4.4-8.7 5.5-19.4998.9-32.1998-8.1-22.6-17.6-60-57.2999-56.8-25.9 2.1-44.6 24.1-47.8 59.5-3 33.1998 15.1 50.3998 41.8 54.6998.4 5.3 3 8.6 6.5 9.1 3.8.6 7.5-2.3 9.3-8.1 12.1-.3 26.1999-5 36.3999-13.6 6.8 4.5 12.5 4.7 15 1.6 2.5-3.2 1.1-8.6-4.8-14.2Z"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M133.272 110.65c4.4-8.7 5.5-19.4998.9-32.1998-8.1-22.6-17.6-60-57.2999-56.8-25.9 2.1-44.6 24.1-47.8 59.5-3 33.1998 15.1 50.3998 41.8 54.6998.4 5.3 3 8.6 6.5 9.1 3.8.6 7.5-2.3 9.3-8.1 12.1-.3 26.1999-5 36.3999-13.6 6.8 4.5 12.5 4.7 15 1.6 2.5-3.2 1.1-8.6-4.8-14.2Z"
    />
    <path
      fill="#1D1D1D"
      d="M50.4722 54.5502c1.2702 0 2.3-1.0298 2.3-2.3 0-1.2703-1.0298-2.3001-2.3-2.3001-1.2703 0-2.3 1.0298-2.3 2.3001 0 1.2702 1.0297 2.3 2.3 2.3ZM97.4722 48.3505c1.2702 0 2.3-1.0298 2.3-2.3 0-1.2703-1.0298-2.3-2.3-2.3-1.2703 0-2.3 1.0297-2.3 2.3 0 1.2702 1.0297 2.3 2.3 2.3Z"
    />
    <path
      fill="#fff"
      d="M120.072 89.1503c10.2 3.4 21.5-.7 21.7-7 .1-5.7-7.3-10.9-21.3-6.6"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.015}
      d="M120.072 89.1503c10.2 3.4 21.5-.7 21.7-7 .1-5.7-7.3-10.9-21.3-6.6"
    />
    <path
      fill="#1D1D1D"
      d="M69.2722 58.2503c-4.3 0-7.8 0-7.8-3.6 0-3.3 3.5-3.6 7.8-3.6h13.2c4.3 0 7.8 0 7.8 3.6s-3.5 3.6-7.8 3.6h-13.2Z"
    />
    <path
      fill="#fff"
      d="M66.0721 54.6503c1.4 13.8 12.8 26.6 26.6 27.5l2.5.2c5.6999.4 10.3999-4.6 9.5999-10.3-.5-3.5-3-6.4-6.3999-7.4l-4.5-1.3c-4.3-1.2-8-4.5-9.5-8.6"
    />
    <path
      fill="#F46147"
      fillOpacity={0.7}
      d="M66.0721 54.6503c1.4 13.8 12.8 26.6 26.6 27.5l2.5.2c5.6999.4 10.3999-4.6 9.5999-10.3-.5-3.5-3-6.4-6.3999-7.4l-4.5-1.3c-4.3-1.2-8-4.5-9.5-8.6"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M66.0721 54.6503c1.4 13.8 12.8 26.6 26.6 27.5l2.5.2c5.6999.4 10.3999-4.6 9.5999-10.3-.5-3.5-3-6.4-6.3999-7.4l-4.5-1.3c-4.3-1.2-8-4.5-9.5-8.6"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M74.5721 54.9504c.8 5.5 4.5 11.2 10.2 14.4"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M180.751 95 155.2 97.7043M176.751 115l-25.551 2.704"
    />
    <path
      fill="#fff"
      d="M151.385 32.228 138.7 40.8038l15.227 2.0287c3.837.5111 7.085-2.7744 6.522-6.5972-.628-4.2599-5.503-6.4152-9.064-4.0073Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="M151.385 32.228 138.7 40.8038l15.227 2.0287c3.837.5111 7.085-2.7744 6.522-6.5972-.628-4.2599-5.503-6.4152-9.064-4.0073Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M151.385 32.228 138.7 40.8038l15.227 2.0287c3.837.5111 7.085-2.7744 6.522-6.5972-.628-4.2599-5.503-6.4152-9.064-4.0073Z"
    />
    <path
      fill="#fff"
      d="m134.01 21.4796-4.013 11.8129 10.884-6.1804c2.743-1.5573 3.233-5.2894.986-7.4939-2.505-2.4565-6.731-1.4554-7.857 1.8614Z"
    />
    <path
      fill="#5A3DE6"
      fillOpacity={0.2}
      d="m134.01 21.4796-4.013 11.8129 10.884-6.1804c2.743-1.5573 3.233-5.2894.986-7.4939-2.505-2.4565-6.731-1.4554-7.857 1.8614Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="m134.01 21.4796-4.013 11.8129 10.884-6.1804c2.743-1.5573 3.233-5.2894.986-7.4939-2.505-2.4565-6.731-1.4554-7.857 1.8614Z"
    />
    <path
      fill="#C874E0"
      stroke="#1D1D1D"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M122.2 45.5c-30.0001-14-69.5-12.5-87 5L43.7 36c21-12.5 50.4999-11.5 72.5 0l6 9.5Z"
    />
  </svg>
);
